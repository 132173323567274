import PropTypes from "prop-types";
import React from "react";
import { PageTitle } from "../../Components/Landmarks";
import { locations } from "../../Utilities/Location";
import { Allow, allow, policyEvents } from "../../Components/Authorize";
import { LayoutMedium } from "../../Components/Layout";
import { DashboardTile } from "./DashboardTile";
import { GridContainer } from "../../Components/Grid";

export const DashboardContainer = ({ header = "Dashboard", message = "" }) => {
  const canViewStudents = allow(policyEvents.VIEW_STUDENTS);
  return (
    <>
      <LayoutMedium>
        <PageTitle h1={header} />
        {message && <p className="text-center">{message}</p>}

        <GridContainer hasMarginX hasMarginY className={`dashboard`}>
          <Allow policyEvent={policyEvents.VIEW}>
            <DashboardTile
              {...locations.USER_MANAGEMENT.link}
              description={"View and manage all users"}
            />
          </Allow>
          {canViewStudents && (
            <DashboardTile
              {...locations.STUDENT_MANAGEMENT.link}
              description={"Search, view, update"}
            />
          )}
          <DashboardTile
            {...locations.LIBRARY_CATALOG.link}
            description={"Search, view, update"}
          />
        </GridContainer>
      </LayoutMedium>
    </>
  );
};

DashboardContainer.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string,
  redirect: PropTypes.func.isRequired,
};
