import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LibraryItemView} from "./LibraryItemView";
import {locations} from "../../Utilities/Location";
import libraryCatalogApi from "./libraryCatalogApi";
import {emptyResourceModel} from "./libraryFactory";
import {createListFromArrayOfObjects} from "../../Utilities/Types/listUtilities";

export const LibraryItemContainer = ({
                                         handleApiCall,
                                         libraryItemId,
                                         redirect,
                                     }) => {
    const [item, setItem] = useState();
    const [vendors, setVendors] = useState();
    const [publishers, setPublishers] = useState();

    const handleReturn = () => redirect(locations.LIBRARY_CATALOG.path);

    const handleSaveResource = async (resource) => {
        return handleApiCall(() => libraryCatalogApi.saveResource(resource),
            handleReturn);
    };

    useEffect(() => {
        const getResource = () => {
            handleApiCall(() => libraryCatalogApi.getResource(libraryItemId), (results = emptyResourceModel) => setItem(results));
        }
        const getVendors = () => {
            handleApiCall(() => libraryCatalogApi.getVendors(), (results) =>
                setVendors(createListFromArrayOfObjects(results, "vendorId", "name")));
        }
        const getPublishers = () => {
            handleApiCall(() => libraryCatalogApi.getPublishers(), (results) =>
                setPublishers(createListFromArrayOfObjects(results, "publisherId", "name")));
        }

        if (libraryItemId && !item)
            getResource();
        if (!vendors)
            getVendors();
        if (!publishers)
            getPublishers();

    }, [libraryItemId]);

    if (!item) return <></>;

    return <>
        <PageTitle h1={`Library Item`}/>
        <LibraryItemView
            handleSaveResource={handleSaveResource}
            handleReturn={handleReturn}
            item={item}
            publishers={publishers}
            vendors={vendors}
        />
    </>;
};

LibraryItemContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    libraryItemId: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
};