import placeholder from "../../images/library-placeholder.svg";
import {AVAILABILITY_LIST, FORMAT_LIST, GRADE_LEVEL_LIST} from "./libraryConstants";

export const resourceModel = ({
                                  author,
                                  availability,
                                  barCode,
                                  catalogId,
                                  copies,
                                  copyright,
                                  cost,
                                  description,
                                  format,
                                  fundingSource,
                                  gradeLevel,
                                  isbn10,
                                  isbn13,
                                  location,
                                  numberOfCopiesToAdd = 0,
                                  numberOfVolumes,
                                  previewIsbn,
                                  publisher,
                                  publisherId,
                                  purchaseOrderNumber,
                                  title,
                                  vendor,
                                  vendorId,
                                  whatsNewEndDate,
                                  whatsNewStartDate,
                              } = {}) => ({
    author,
    availability,
    barCode,
    catalogId,
    copies,
    copyright,
    cost,
    description,
    format,
    fundingSource,
    gradeLevel,
    isbn10,
    isbn13,
    location,
    numberOfCopiesToAdd,
    numberOfVolumes,
    previewIsbn,
    publisher,
    publisherId,
    purchaseOrderNumber,
    title,
    vendor,
    vendorId,
    whatsNewEndDate,
    whatsNewStartDate,
});

const testResource = {
    author: "Test Author",
    availability: "2",
    barCode: "test barCode",
    copyright: "01/06/1981",
    cost: "3.14",
    description: "test description",
    format: "2",
    fundingSource: "2",
    gradeLevel: "2",
    isbn10: "1234567890",
    isbn13: "1234567890123",
    location: "1",
    purchaseOrderNumber: "test purchaseOrderNumber",
    title: "test title",
    numberOfVolumes: "1",
};
export const testResourceModel = resourceModel(testResource);
export const emptyResourceModel = resourceModel();


export const publisherModel = ({
                                   publisherId,
                                   name,
                               } = {}) => ({
    publisherId,
    name,
});
const testPublisher = {
    name: "Test Publisher",
};
export const emptyPublisherModel = publisherModel();
export const testPublisherModel = publisherModel(testPublisher);


export const vendorModel = ({
                                vendorId,
                                name,
                                email,
                                contactName,
                                phoneNumber,
                                faxNumber,
                                address,
                                city,
                                state,
                                zipCode,
                                website,
                            } = {}) => ({
    vendorId,
    name,
    email,
    contactName,
    phoneNumber,
    faxNumber,
    address,
    city,
    state,
    zipCode,
    website,
});
const testVendor = {
    name: "Test Vendor",
    email: "Vendor@vending.com",
    contactName: "Contact Vendor",
    phoneNumber: "123-456-7890",
    faxNumber: "098-765-4321",
    address: "123 Vending St",
    city: "Vendoropolis",
    state: "VN",
    zipCode: "12345",
    website: "www.vending.com",
};
export const emptyVendorModel = vendorModel();
export const testVendorModel = vendorModel(testVendor);


function createDetails({author, availability, format, gradeLevel}) {
    let details = "";
    details += author ? `<div><b>Author:</b> ${author}</div>` : "";
    details += format ? `<div><b>Format:</b> ${FORMAT_LIST.find(f => f.value === format)?.text}</div>` : "";
    details += gradeLevel ? `<div><b>Grade Level:</b> ${GRADE_LEVEL_LIST.find(g => g.value === gradeLevel)?.text}</div>` : "";
    details += availability ? `<div><b>Availability:</b> ${AVAILABILITY_LIST.find(a => a.value === availability)?.text}</div>` : "";
    return details ? details + "<br/>" : '';
}

export const catalogItemModel = ({
                                     author,
                                     availability,
                                     catalogId,
                                     description,
                                     format,
                                     gradeLevel,
                                     title,
                                 } = {}) => ({
    catalogId,
    description: description ? `<p>${description}</p>` : "",
    details: createDetails({author, availability, format, gradeLevel}),
    imageSrc: placeholder,
    title,
});

