import {createListFromObject} from "../../Utilities/Types/listUtilities";
import {GRADE_LEVEL} from "../Student/studentConstants";

export const AVAILABILITY = {
    "In Stock": 1,
    "Obtainable": 2,
    "Special Order Request": 3,
};

export const AVAILABILITY_LIST = createListFromObject(AVAILABILITY);

export const FORMAT = {
    "Ueb": 1,
    "Nemeth": 2,
    "Ebae": 3,
    "Uncontracted": 4,
    "Large Print": 5,
    "Digital": 6,
};

export const FORMAT_LIST = createListFromObject(FORMAT);

export const FUNDING_SOURCE = {
    "0816": 1,
    "0817": 2,
    "Quota": 3,
    "Internal Production": 4,
};

export const FUNDING_SOURCE_LIST = createListFromObject(FUNDING_SOURCE);

export const GRADE_LEVEL_LIST = createListFromObject(GRADE_LEVEL);

export const LOCATION = {
    "OutOnLoan": 1,
    "ShelfLocation": 2,
    "Purged": 3,
};

export const LOCATION_LIST = createListFromObject(LOCATION);
