import React from "react";
import PropTypes from "prop-types";
import StudentApprovalRow from "./StudentApprovalRow";
import {GridTable} from "../../../Components/Grid";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";

const StudentApprovalView = ({
                                 handleSetStudentSearchCriteria,
                                 onApprovalStatusClick,
                                 redirect,
                                 students,
                                 studentSearchCriteria
                             }) => {

    const handleOrderClick = (event) => {
        event.preventDefault();
        let criteria = {...studentSearchCriteria};
        criteria.UpdateSorting(event.target.dataset.id);
        criteria.loadSearchResults = false;
        handleSetStudentSearchCriteria(criteria);
    };

    const noResults = isArrayNullOrEmpty(students);

    return <>
        <GridTable parentClass={`margin--topNone`}>
            <thead>
            <tr>
                <th onClick={handleOrderClick} className="is-clickable" data-id="fullName">Name</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="dateOfBirthInDateFormat">Date Of Birth</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="gradeLevel">Grade Level</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="language">Language</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="primaryReadingMedium">Prm</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="districtName">District</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="userFullName">Patron Name</th>
                <th onClick={handleOrderClick} className={"is-clickable text-center"} data-id="studentApprovalStatusName"
                    scope={"col"}>Approval Status
                </th>
                <th scope={"col"} className={"align-center"}>
                    Actions
                </th>
            </tr>
            </thead>
            <tbody>
            {
                students.map((student, index) => {
                        return (<StudentApprovalRow
                            key={index}
                            onApprovalStatusClick={onApprovalStatusClick}
                            redirect={redirect}
                            student={student}
                        />);
                    }
                )
            }
            {
                noResults &&
                <tr data-testid={`no-users-row`}>
                    <td colSpan={9} className={`text-center`}>No results found.</td>
                </tr>
            }
            </tbody>
        </GridTable>
    </>;
}

StudentApprovalView.propTypes = {
    handleSetStudentSearchCriteria: PropTypes.func.isRequired,
    onApprovalStatusClick: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    studentSearchCriteria: PropTypes.object,
    students: PropTypes.array,
}

export default StudentApprovalView;





