import React from "react";
import PropTypes from "prop-types";
import {
    ButtonIconCancel,
    ButtonIconDoneEditing,
    convertInputFormToObject,
    SelectField,
    useInputForm
} from "../../Components/Inputs";
import {LOCATION_LIST} from "./libraryConstants";

export const LibraryItemCopyEdit = ({
                                        copy,
                                        handleCancel,
                                        handleDone,
                                    }) => {

    const copyForm = useInputForm(copy);

    const handleDoneClick = (event) => {
        event.preventDefault();
        const data = convertInputFormToObject(copyForm);
        handleDone(data);
    }

    return <>
        <tr>
            <td className={`text-center`}>{copy.number}</td>
            <td>{copy.barCode}</td>
            <td>
                <SelectField {...copyForm.location} includeDefaultOption={false} options={LOCATION_LIST} showOnlyForSr />
            </td>
            <td className={`action-cell`}>
                <ButtonIconDoneEditing
                    inputDescription={`done editing for copy ${copy.number}`}
                    onClick={handleDoneClick}
                />
                <ButtonIconCancel
                    inputDescription={`cancel editing for copy ${copy.number}`}
                    onClick={handleCancel}
                />
            </td>
        </tr>
    </>;
};

LibraryItemCopyEdit.propTypes = {
    copy: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleDone: PropTypes.func.isRequired,
};