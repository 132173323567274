import React from "react";
import PropTypes from "prop-types";
import {ButtonIconEdit} from "../../Components/Inputs";
import {getLocationTextByValue} from "./libraryUtility";

export const LibraryItemCopyDisplay = ({
                                           copy,
                                           handleEdit,
                                       }) => {
    const isClickDisabled = !handleEdit;

    return <>
        <tr>
            <td className={`text-center`}>{copy.number}</td>
            <td>{copy.barCode}</td>
            <td>{getLocationTextByValue(copy.location)}</td>
            <td className={`action-cell`}>
                <ButtonIconEdit
                    disabled={isClickDisabled}
                    inputDescription={`for copy ${copy.number}`}
                    onClick={()=>handleEdit(copy.copyId)}
                />
            </td>
        </tr>
    </>;
};

LibraryItemCopyDisplay.propTypes = {
    copy: PropTypes.object.isRequired,
    handleEdit: PropTypes.func.isRequired,
};