import {searchModel} from "../../Utilities/Search";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import {dynamicSort} from "../../Utilities/Sort";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {STUDENT_APPROVAL_STATUS_FOR_SEARCH} from "./studentConstants";

export const studentSearchModel = (studentId, studentName = "", dateOfBirth = "", gradeLevel = "", language = "", districtId = "", patronName = "", isActive = "true", studentApprovalStatus = `${STUDENT_APPROVAL_STATUS_FOR_SEARCH.PendingNeedInfo}`, studentStatus = "", studentInactiveReason = "") => {
    return Object.assign({}, searchModel(studentId.toString(), "name", false, "name"), {
        studentName,
        dateOfBirth,
        gradeLevel,
        language,
        districtId,
        patronName,
        isActive,
        studentApprovalStatus,
        studentStatus,
        studentInactiveReason,
        ClearStudentSearch: clearStudentSearch,
        ApplySorting: applySorting,
    });
};

function clearStudentSearch() {
    this.studentName = "";
    this.dateOfBirth = "";
    this.gradeLevel = "";
    this.language = "";
    this.districtId = "";
    this.patronName = "";
    this.isActive = "";
    this.studentApprovalStatus = "";
    this.studentStatus = "";
    this.studentInactiveReason = "";
    this.ClearSearch(false);
}

function applySorting(studentSearchCriteria, students) {

    if (isNullOrUndefined(students) || isArrayNullOrEmpty(students))
        return students;

    return students.sort(dynamicSort(studentSearchCriteria.sortByColumn, studentSearchCriteria.sortDescending));
}

