import React from "react";
import PropTypes from "prop-types";
import {HtmlDisplay} from "../../Components/Display";
import {Button} from "../../Components/Inputs";
import {GridCell, GridContainer} from "../../Components/Grid";
import {Allow, policyEvents} from "../../Components/Authorize";

export const LibraryCatalogItem = ({
                                       catalogId,
                                       description,
                                       details,
                                       handleClickEdit,
                                       title,
                                   }) => {

    return <>
        <GridContainer className={`catalog__item`} hasMarginX>
            <GridCell>
                <h3 id={`title${catalogId}`}>{title}</h3>

                <HtmlDisplay html={description}/>

                <HtmlDisplay html={details}/>

                <Button isCollapsed isPrimary label={`Add to Queue`} name={`btnAddQueue${catalogId}`} onClick={() => {
                }}/>
                <Allow policyEvent={policyEvents.MANAGE_CATALOG}>
                    <Button isCollapsed label={`Edit Item`} name={`btnEdit${catalogId}`} onClick={handleClickEdit}/>
                </Allow>
            </GridCell>
        </GridContainer>
    </>;
};

LibraryCatalogItem.propTypes = {
    description: PropTypes.string,
    details: PropTypes.string,
    catalogId: PropTypes.string,
    handleClickEdit: PropTypes.func.isRequired,
    title: PropTypes.string,
};