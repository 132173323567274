import PropTypes from "prop-types";
import {GridCell, gridConstants} from "../../../Components/Grid";
import React, {useEffect, useState, useRef} from "react";
import {ButtonIconHistory} from "../../../Components/Inputs/Buttons/ButtonIcon/ButtonIcon";
import {catchError} from "../../../Utilities/Api";
import GridTable from "../../../Components/Grid/GridTable";
import {allow, policyEvents} from "../../../Components/Authorize";
import {Button} from "../../../Components/Inputs";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";
import {logEntryModel} from "../studentDetailsFactory";

export const StudentFieldChangeLogModal = ({
                                               children,
                                               fieldName,
                                               isEditing,
                                               inputForm
                                           }) => {
    const [fieldChanges, setFieldChanges] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);
    const triggerButtonRef = useRef(null);

    const changeLog = inputForm.studentChangeLogs.value;
    const label = inputForm[fieldName].label;
    const canViewHistory = allow(policyEvents.VIEW_STUDENT_HISTORY);

    function handleHistoryButtonClick(event) {
        event.preventDefault();
        setShowModal(!showModal);
    }

    const parseChangeLog = (fieldName, changeLog) => {
        const lowerCaseFieldName = fieldName.toLowerCase();
        return changeLog.reduce((entries, logEntry) => {
            if (logEntry) {
                try {
                    const logEntryData = logEntryModel({...logEntry});
                    const editLog = JSON.parse(logEntryData.editLog);
                    const matchingField = Object.keys(editLog).find(key => key.toLowerCase() === lowerCaseFieldName);
                    if (matchingField) {
                        const gridRow =
                            <tr>
                                <td>{editLog[matchingField]}</td>
                                <td>{logEntryData.dateOfChange}</td>
                                <td>{logEntryData.patronFullName}</td>
                            </tr>;
                        entries.push(gridRow);
                    }
                } catch (error) {
                    catchError("Error parsing JSON in change log entry:", error);
                }
            }
            return entries;
        }, []);
    }

    useEffect(() => {
        if (fieldName && changeLog)
            setFieldChanges(parseChangeLog(fieldName, changeLog));
    }, [fieldName, changeLog]);

    useEffect(() => {
        if (showModal) {
            const focusableElements = modalRef.current.querySelectorAll(
                'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const trapFocus = (e) => {
                if (e.key === "Tab") {
                    if (e.shiftKey) {
                        if (document.activeElement === firstElement) {
                            e.preventDefault();
                            lastElement.focus();
                        }
                    } else {
                        if (document.activeElement === lastElement) {
                            e.preventDefault();
                            firstElement.focus();
                        }
                    }
                }
            };

            document.addEventListener("keydown", trapFocus);

            firstElement.focus();

            return () => {
                document.removeEventListener("keydown", trapFocus);
                if (triggerButtonRef.current) {
                    triggerButtonRef.current.focus();
                }
            };
        }
    }, [showModal]);

    return (
        <>
            {
                canViewHistory && showModal && isEditing && !isArrayNullOrEmpty(fieldChanges) &&
                <div className="modal" role="dialog" aria-labelledby="modalTitle" aria-modal="true" ref={modalRef}>
                    <GridCell className={`modal`} medium_columns={gridConstants.column.TWELVE}>
                        <div className={"tile__header"} id="modalTitle">
                            {`Change History for ${label}`}
                        </div>
                        <GridTable parentClass={`margin--topNone`}>
                            <thead>
                            <tr>
                                <th scope="col" data-id="previousValue">Previous Value</th>
                                <th scope="col" data-id="dateOfChange">Date of Change</th>
                                <th scope="col" data-id="patronResponsibleForChange">Patron Responsible for Change</th>
                            </tr>
                            </thead>
                            <tbody>
                            {fieldChanges.map((row, index) =>
                                <React.Fragment key={index}>
                                    {row}
                                </React.Fragment>
                            )}
                            </tbody>
                        </GridTable>
                        <GridCell medium_columns={gridConstants.column.ONE} className={`horizontal-right`}>
                            <Button
                                isPrimary
                                label={`Close`}
                                name={`btnClose`}
                                isCollapsed={true}
                                onClick={handleHistoryButtonClick}
                                aria-label="Close Change History Modal"
                            />
                        </GridCell>
                    </GridCell>
                </div>
            }
            <GridCell className={`changelog-wrapper`} hasMarginX hasMarginY>
                <div className={`changelog-content`}>
                    {children}
                </div>
                {
                    canViewHistory && isEditing &&
                    <div className={`changelog-icon`}>
                        {
                            !isArrayNullOrEmpty(fieldChanges) &&
                            <ButtonIconHistory
                                inputDescription={`History for ${fieldName}`}
                                onClick={(event) => {
                                    handleHistoryButtonClick(event);
                                    triggerButtonRef.current = event.currentTarget;
                                }}
                                aria-label={`Show history for ${fieldName}`}
                            />
                        }
                    </div>
                }
            </GridCell>
        </>
    );
};

StudentFieldChangeLogModal.propTypes = {
    children: PropTypes.any.isRequired,
    fieldName: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    inputForm: PropTypes.object.isRequired
};
