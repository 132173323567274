import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import {locations} from "../../Utilities/Location";
import {DashboardContainer} from "../../Containers/Dashboard/";
import {LandingContainer} from "../../Containers/Landing/";
import {LibraryCatalogContainer, LibraryCatalogWhatsNewContainer, LibraryItemContainer, LibraryPublisherContainer, LibraryVendorContainer} from "../../Containers/Library";
import {NotFoundContainer} from "../../Containers/NotFound/";
import {PageSetup} from "../../Containers/PageSetup/";
import {PasswordRequestContainer, PasswordUpdateContainer} from "../../Containers/Password";
import {RegisterContainer} from "../../Containers/Register/";
import {SignInContainer} from "../../Containers/SignIn/";
import {SignOutContainer} from "../../Containers/SignOut/";
import {StudentApprovalContainer, StudentRegistrationContainer} from "../../Containers/Student/";
import {UserApprovalContainer} from "../../Containers/User/";
import {UserProfileContainer} from "../../Containers/User/UserProfile/UserProfileContainer";

const SiteRoutes = () => {

    const Dashboard = <PageSetup component={DashboardContainer} path={locations.DASHBOARD.path} showSignOutLink/>;
    const ForgotPasswordPage = <PageSetup component={PasswordRequestContainer} path={locations.FORGOT_PASSWORD.path}
                                          showSignInLink/>;
    const LandingPage = <PageSetup component={LandingContainer} path={locations.LANDING.path} showSignOutLink
                                   showDashboardLink/>;
    const LibraryCatalogWhatsNewPage = <PageSetup component={LibraryCatalogWhatsNewContainer} path={locations.LIBRARY_CATALOG.path}
                                          showSignOutLink showDashboardLink/>;
    const LibraryCatalogPage = <PageSetup component={LibraryCatalogContainer} path={locations.LIBRARY_CATALOG.path}
                                   showSignOutLink showDashboardLink/>;
    const LibraryItemPage = <PageSetup component={LibraryItemContainer} path={locations.LIBRARY_ITEM.path}
                                   showSignOutLink showDashboardLink/>;
    const LibraryPublisherPage = <PageSetup component={LibraryPublisherContainer} path={locations.LIBRARY_PUBLISHER.path}
                                       showSignOutLink showDashboardLink/>;
    const LibraryVendorPage = <PageSetup component={LibraryVendorContainer} path={locations.LIBRARY_VENDOR.path}
                                         showSignOutLink showDashboardLink/>;
    const NotFoundPage = <PageSetup component={NotFoundContainer} path={locations.NOT_FOUND.path}/>;
    const RegistrationPage = <PageSetup component={RegisterContainer} path={locations.REGISTER.path} showSignInLink/>;
    const SignInPage = <PageSetup component={SignInContainer} path={locations.SIGN_IN.path} showLandingLink/>;
    const SignOutPage = <PageSetup component={SignOutContainer} path={locations.SIGN_OUT.path}/>;
    const StudentApprovalPage = <PageSetup component={StudentApprovalContainer} path={locations.STUDENT_MANAGEMENT.path}
                                           showDashboardLink showSignOutLink/>;
    const UpdatePasswordPage = <PageSetup component={PasswordUpdateContainer} path={locations.UPDATE_PASSWORD.path}
                                          showSignInLink/>;
    const UserApprovalPage = <PageSetup component={UserApprovalContainer} path={locations.USER_MANAGEMENT.path}
                                        showDashboardLink showSignOutLink/>;
    const UserProfilePage = <PageSetup component={UserProfileContainer}
                                       path={locations.USER_PROFILE.path} showDashboardLink showSignOutLink/>;

    const StudentRegistrationPage = <PageSetup component={StudentRegistrationContainer}
                                               path={locations.REGISTER_STUDENT.path} showDashboardLink
                                               showSignOutLink/>;
    const EditStudentPage = <PageSetup component={StudentRegistrationContainer}
                                       path={locations.EDIT_STUDENT.path} showDashboardLink showSignOutLink/>;


    return (
        <Routes>
            <Route path={locations.SIGN_IN.path} element={SignInPage}/>
            <Route path={locations.SIGN_OUT.path} element={SignOutPage}/>
            <Route path={locations.REGISTER.path} element={RegistrationPage}/>
            <Route path={locations.LANDING.path} element={LandingPage}/>

            {/* Library */}
            <Route path={locations.LIBRARY_WHATS_NEW.path} element={LibraryCatalogWhatsNewPage}/>
            <Route path={locations.LIBRARY_CATALOG.path} element={LibraryCatalogPage}/>
            <Route path={locations.LIBRARY_ITEM.path} element={LibraryItemPage}/>
            <Route path={locations.LIBRARY_PUBLISHER.path} element={LibraryPublisherPage}/>
            <Route path={locations.LIBRARY_VENDOR.path} element={LibraryVendorPage}/>

            {/* Password */}
            <Route path={locations.FORGOT_PASSWORD.path} element={ForgotPasswordPage}/>
            <Route path={locations.UPDATE_PASSWORD.path} element={UpdatePasswordPage}/>
            <Route path={locations.DASHBOARD.path} element={Dashboard}/>

            {/* User */}
            <Route path={locations.USER_MANAGEMENT.path} element={UserApprovalPage}/>
            <Route path={locations.USER_PROFILE.path} element={UserProfilePage}/>

            {/* Student */}
            <Route path={locations.STUDENT_MANAGEMENT.path} element={StudentApprovalPage}/>
            <Route path={locations.REGISTER_STUDENT.path} element={StudentRegistrationPage}/>
            <Route path={locations.EDIT_STUDENT.path} element={EditStudentPage}/>

            {/* Sad Routes */}
            <Route exact path="/" element={<Navigate to={locations.LANDING.path} replace/>}/>
            <Route path="/*" element={NotFoundPage}/>
            <Route exact path="*" element={<Navigate to={locations.LANDING.path} replace/>}/>
        </Routes>
    );
};

export {SiteRoutes};