import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {
    clearInputFormErrorFields,
    convertInputFormToObject,
    createYesNoList, inputFormIsValid
} from "../../../Components/Inputs/inputUtility";
import {
    RadioList,
    SelectField,
    TextField,
    DateTimePickerField,
    CheckBoxField, Button,
} from "../../../Components/Inputs";
import {createListFromArrayOfObjects} from "../../../Utilities/Types/listUtilities";
import {
    VISUALLY_IMPAIRED_STUDENT_PLAN_LIST,
    PERIPHERAL_FIELD_LOSS_LIST,
    EDUCATIONAL_FACILITY_LIST,
    GRADE_LEVEL_LIST,
    PRIMARY_READING_MEDIUM_LIST,
    SECONDARY_READING_MEDIUM_LIST,
    OTHER_READING_MEDIUM_LIST,
    LANGUAGE_LIST,
    COMPLETE_READING_MEDIUMS,
    VISUAL_ACUITY_LIST,
    STUDENT_APPROVAL_STATUS_LIST,
    STUDENT_STATUS_LIST,
    STUDENT_INACTIVE_REASON_LIST, STUDENT_APPROVAL_STATUS, STUDENT_STATUS
} from "../studentConstants";
import {GridCell, GridContainer} from "../../../Components/Grid";
import {arraysEqual} from "../../../Utilities/Types/arrayUtilities";
import {StudentRegistrationDuplicateStudentsView} from "./StudentRegistrationDuplicateStudentsView";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {StudentFieldChangeLogModal} from "./StudentFieldChangeLogModal";

export const StudentRegistrationForm = ({
                                            checkAndSetError,
                                            checkForDuplicateStudents,
                                            districts,
                                            duplicateStudents,
                                            formVisibility,
                                            isEditing,
                                            isLoading,
                                            patronName,
                                            readOnly,
                                            setWarnings,
                                            studentDetailsForm
                                        }) => {


    const yesNoList = createYesNoList();

    const studentChangeLogModalProps = {
        isEditing: isEditing,
        inputForm: studentDetailsForm,
    };

    const getReadingMediumOptions = (field, fullOptions) => {
        const allFields = [
            "primaryReadingMedium",
            "secondaryReadingMedium",
            "otherReadingMedium",
        ];
        let options = fullOptions;
        allFields.forEach((fieldName) => {
            if (
                studentDetailsForm[fieldName].value &&
                fieldName !== field &&
                studentDetailsForm[fieldName].value !==
                COMPLETE_READING_MEDIUMS.NotApplicable.id
            ) {
                options = options.filter(
                    (option) => option.value !== studentDetailsForm[fieldName].value
                );
            }
        });

        return options;
    };

    function calculateAge(birthdate) {
        const today = new Date();
        const birthDate = new Date(birthdate.value);
        if (birthDate > today) {
            return "The date you entered is invalid.";
        }

        let ageInYears = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();

        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            ageInYears--;
        }

        if (ageInYears > 0) {
            const ageLabel = ageInYears === 1 ? " year" : " years";
            return `This student is ${ageInYears}${ageLabel} old.`;
        }

        const months = (today.getFullYear() - birthDate.getFullYear()) * 12 + monthDifference - (dayDifference < 0 ? 1 : 0);
        const monthLabel = months === 1 ? " month" : " months";
        return `This student is ${months}${monthLabel} old.`;
    }

    const handleApprovalStatusChange = (event) => {

        const updatedStudentDetailsForm = {...studentDetailsForm};

        if (event.target.value === STUDENT_APPROVAL_STATUS.Approved.toString()) {
            updatedStudentDetailsForm.studentStatus.value = STUDENT_STATUS.Active.id;
            updatedStudentDetailsForm.studentInactiveReason.value = null;
        }
        updatedStudentDetailsForm.studentApprovalStatus.setValue(event.target.value);

        studentDetailsForm.setValue({...updatedStudentDetailsForm})
    };

    const handleClickCheckForDuplicateStudents = (event) => {
        event.preventDefault();

        if (isFormValidForDuplicateStudentsCheck()) {
            const registerModel = convertInputFormToObject(studentDetailsForm);
            checkForDuplicateStudents(registerModel);

            const updatedStudentDetailsForm = {...studentDetailsForm};
            updatedStudentDetailsForm.overrideCheck.value = false;
            studentDetailsForm.setValue({...updatedStudentDetailsForm})
        }
    };

    const isFormValidForDuplicateStudentsCheck = () => {
        clearInputFormErrorFields(studentDetailsForm);

        checkAndSetError([
            studentDetailsForm.lastName,
            studentDetailsForm.firstName,
        ]);

        let isValid = inputFormIsValid(studentDetailsForm);

        if (!isValid)
            setWarnings([
                `${studentDetailsForm.studentStatus.error} Missing required items.  Please review your responses and try submitting again.`,
            ]);

        return isValid;
    };

    useEffect(() => {
        const updatedStudentDetailsForm = {...studentDetailsForm};

        if (studentDetailsForm.hasSignedParentalConsent.value && studentDetailsForm.understandsNoAphAccess.value) {
            updatedStudentDetailsForm.understandsNoAphAccess.value = false;
        }
        if (!formVisibility.isDualSensoryImpaired && studentDetailsForm.isFaviRegistered.value) {
            updatedStudentDetailsForm.isFaviRegistered.value = "";
        }
        if (formVisibility.isNeuroImpairment && studentDetailsForm.hasVisualImpairment.value) {
            updatedStudentDetailsForm.hasVisualImpairment.value = "";
        }
        if (!formVisibility.isAdGradeLevel && studentDetailsForm.hasInstructionalHours.value) {
            updatedStudentDetailsForm.hasInstructionalHours.value = "";
        }
        if (!formVisibility.isNotCertified && studentDetailsForm.understandsNotCertified.value) {
            updatedStudentDetailsForm.understandsNotCertified.value = "";
        }
        if (!formVisibility.isHomeSchooledStudent && studentDetailsForm.hasHomeSchoolRequirementAgreement.value) {
            updatedStudentDetailsForm.hasHomeSchoolRequirementAgreement.value = "";
        }
        if (!formVisibility.isInactive && studentDetailsForm.studentInactiveReason.value) {
            updatedStudentDetailsForm.studentInactiveReason.value = null;
        }

        if (formVisibility.hideReadingMediums) {
            updatedStudentDetailsForm.secondaryReadingMedium.value = COMPLETE_READING_MEDIUMS.NotApplicable.id;
            updatedStudentDetailsForm.otherReadingMedium.value = COMPLETE_READING_MEDIUMS.NotApplicable.id;
        }

        if (arraysEqual(studentDetailsForm, updatedStudentDetailsForm)) {
            studentDetailsForm.setValue({...updatedStudentDetailsForm})
        }
    }, [studentDetailsForm, formVisibility]);

    return (
        <>
            <CheckBoxField
                {...studentDetailsForm.hasSignedParentalConsent}
                checked={!!studentDetailsForm.hasSignedParentalConsent.value}
                disabled={readOnly}
                label={
                    "By checking this box, I certify that according to FERPA and HIPAA requirements, the district has on file a signed consent to share student identifiable information with FIMC-VI and APH. (For students under the age of 18 years, the consent must be signed by parents/guardians; for students that have reached the age of majority, consent must be signed by the student)."
                }
                value={studentDetailsForm.hasSignedParentalConsent.value ? "" : "true"}
            />

            {
                !formVisibility.isParentalConsent &&
                <CheckBoxField
                    {...studentDetailsForm.understandsNoAphAccess}
                    checked={!!studentDetailsForm.understandsNoAphAccess.value}
                    disabled={readOnly}
                    label={
                        "I understand, I will be unable to order APH products for this student until parental consent is provided, but I will still be able to order textbooks."
                    }
                    value={studentDetailsForm.understandsNoAphAccess.value ? "" : "true"}
                />
            }

            <RadioList
                {...studentDetailsForm.hasNeurologicalImpairment}
                disabled={readOnly}
                legend={`Does this child have a neurological, cortical, or cerebral visual impairment (e.g. brain injury or dysfunction) as indicated by medical documentation?`}
                options={yesNoList}
            />

            {
                !formVisibility.isNeuroImpairment &&
                !isNullOrUndefined(studentDetailsForm.hasNeurologicalImpairment.value) &&
                <RadioList
                    {...studentDetailsForm.hasVisualImpairment}
                    disabled={readOnly}
                    legend={`Is there a medical report on file indicating a visual impairment?`}
                    options={yesNoList}
                />
            }

            {
                formVisibility.isNotNeuroImpairmentAndMedicalReport &&
                <GridCell>
                    <p className="student-reg --warning text-center">
                        You must select &lsquo;Yes&rsquo; to one of the two previous
                        questions to continue.
                    </p>
                </GridCell>
            }

            {
                formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                <>
                    <StudentFieldChangeLogModal
                        {...studentChangeLogModalProps}
                        fieldName={studentDetailsForm.firstName.name}>
                        <TextField {...studentDetailsForm.firstName}
                                   disabled={readOnly}
                                   label={`First Name`}/>
                    </StudentFieldChangeLogModal>

                    <StudentFieldChangeLogModal
                        {...studentChangeLogModalProps}
                        fieldName={studentDetailsForm.middleName.name}>
                        <TextField {...studentDetailsForm.middleName}
                                   disabled={readOnly}
                                   label={`Middle Name`}/>
                    </StudentFieldChangeLogModal>

                    <StudentFieldChangeLogModal
                        {...studentChangeLogModalProps}
                        fieldName={studentDetailsForm.lastName.name}>
                        <TextField
                            {...studentDetailsForm.lastName}
                            disabled={readOnly}
                            label={`Last Name`}/>
                    </StudentFieldChangeLogModal>

                    <StudentFieldChangeLogModal
                        {...studentChangeLogModalProps}
                        fieldName={studentDetailsForm.dateOfBirth.name}>
                        <DateTimePickerField
                            {...studentDetailsForm.dateOfBirth}
                            disabled={readOnly}
                            label={`Date of Birth`}
                        />
                    </StudentFieldChangeLogModal>

                    {
                        formVisibility.hasBirthdate &&
                        <GridCell>
                            <p>
                                <strong>
                                    {calculateAge(studentDetailsForm.dateOfBirth)}

                                </strong>
                            </p>
                        </GridCell>
                    }

                    <StudentFieldChangeLogModal
                        {...studentChangeLogModalProps}
                        fieldName={studentDetailsForm.gradeLevel.name}>
                        <SelectField
                            {...studentDetailsForm.gradeLevel}
                            disabled={readOnly}
                            helpText={`<p><b>IP</b> Infants: Children of preschool age served by infant programs (Birth to age three [3]).<br/><b>PS</b> (Pre-K) Preschool Students: Children of preschool age served by preschool programs.<br/><b>KG</b> Kindergarten Students: Children enrolled in kindergarten classes.<br/><b>01-11</b> Students of School Age: as determined by state law, in regular academic grades 1 through 11. Select specific grade placement.<br/><b>12</b> Students who are in grade 12 or are continuing to receive special education services under IDEA until the student turns age twenty-two (22).<br/><b>AD</b> Adult Students: Students ages 22 and above by the first Monday in January must be reported as adults, even if they are in grade 12. (Note requirement of at least 120 hours of documented educational instruction during the preceding calendar year.)</p>`}
                            label={`Grade Level`}
                            options={GRADE_LEVEL_LIST}
                        />
                    </StudentFieldChangeLogModal>

                    {
                        formVisibility.showAgeOverride &&
                        <CheckBoxField
                            {...studentDetailsForm.gradeAgeConfirmation}
                            checked={!!studentDetailsForm.gradeAgeConfirmation.value}
                            disabled={readOnly}
                            label={
                                `I, ${patronName}, confirm that the grade level and age of the student are correct.`
                            }
                            value={studentDetailsForm.gradeAgeConfirmation.value ? "" : "true"}
                        />
                    }
                </>
            }

            {
                formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                <>
                    <GridContainer className={`horizontal-center`} hasMarginX hasMarginY>
                        <Button
                            isPrimary
                            label={`Check For Existing Student`}
                            name={`btnCheckForExistingStudent`}
                            onClick={handleClickCheckForDuplicateStudents}
                            disabled={isLoading || !formVisibility.isFormDataChanged || readOnly}
                        />
                    </GridContainer>

                    {
                        formVisibility.hasDuplicate && !formVisibility.isFormDataChanged &&
                        <>
                            <CheckBoxField
                                {...studentDetailsForm.overrideCheck}
                                checked={!!studentDetailsForm.overrideCheck.value}
                                disabled={readOnly}
                                label={"I have reviewed the potential duplicates below, and the student that I have entered is not a duplicate. Selecting a student from a tile below will over write the student information you entered."}
                                value={studentDetailsForm.overrideCheck.value ? "" : "true"}
                            />

                            {
                                !formVisibility.isOverrideOrNoDuplicate &&
                                <StudentRegistrationDuplicateStudentsView
                                    duplicateStudents={duplicateStudents}
                                />
                            }
                        </>
                    }
                </>
            }

            {
                formVisibility.isOverrideOrNoDuplicate && !formVisibility.isFormDataChanged &&
                <>
                    {
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <>
                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.writtenPlan.name}>
                                <SelectField
                                    {...studentDetailsForm.writtenPlan}
                                    disabled={readOnly}
                                    label={`Which type of written plan addressing the unique needs of the student with visual impairment is on file?`}
                                    options={VISUALLY_IMPAIRED_STUDENT_PLAN_LIST}
                                />
                            </StudentFieldChangeLogModal>

                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.districtId.name}>
                                <SelectField
                                    {...studentDetailsForm.districtId}
                                    disabled={readOnly}
                                    helpText={`<p>This should reflect where the student is enrolled and the primary organization responsible for providing educational services.</p>`}
                                    label={`District`}
                                    options={createListFromArrayOfObjects(
                                        districts,
                                        "id",
                                        "districtName"
                                    )}
                                />
                            </StudentFieldChangeLogModal>
                        </>
                    }

                    {
                        formVisibility.isMedicalReport &&
                        <>
                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.distanceVisualAcuityRight.name}>
                                <SelectField
                                    {...studentDetailsForm.distanceVisualAcuityRight}
                                    disabled={readOnly}
                                    helpText={`<p>Provide distance visual acuity with correction using Snellen charts or equivalents (20/50, 20/400, NLP, CF, HM, etc.). </p>`}
                                    label={`Distance Visual Acuity Right`}
                                    options={VISUAL_ACUITY_LIST}
                                />
                            </StudentFieldChangeLogModal>

                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.distanceVisualAcuityLeft.name}>
                                <SelectField
                                    {...studentDetailsForm.distanceVisualAcuityLeft}
                                    disabled={readOnly}
                                    helpText={`<p>Provide distance visual acuity with correction using Snellen charts or equivalents (20/50, 20/400, NLP, CF, HM, etc.). </p>`}
                                    label={`Distance Visual Acuity Left`}
                                    options={VISUAL_ACUITY_LIST}
                                />
                            </StudentFieldChangeLogModal>

                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.peripheralFieldLoss.name}>
                                <SelectField
                                    {...studentDetailsForm.peripheralFieldLoss}
                                    disabled={readOnly}
                                    helpText={`<p>If the student has a restricted visual field of 20 degrees or less, enter that information (e.g. VF 20, VF 6) if provided by the medical documentation. This information is essential if both acuities are better than 20/200. If the medical eye report does not indicate any of these peripheral field restrictions, leave this section blank.</p>`}
                                    label={`Peripheral Field Loss`}
                                    options={PERIPHERAL_FIELD_LOSS_LIST}
                                />
                            </StudentFieldChangeLogModal>
                        </>
                    }

                    {
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <StudentFieldChangeLogModal
                            {...studentChangeLogModalProps}
                            fieldName={studentDetailsForm.educationalFacility.name}>
                            <SelectField
                                {...studentDetailsForm.educationalFacility}
                                disabled={readOnly}
                                label={`Education Facility Type`}
                                options={EDUCATIONAL_FACILITY_LIST}
                            />
                        </StudentFieldChangeLogModal>
                    }

                    {
                        formVisibility.isHomeSchooledStudent &&
                        <RadioList
                            {...studentDetailsForm.hasHomeSchoolRequirementAgreement}
                            disabled={readOnly}
                            legend={`As the parent of a homeschooled student, I have met all the requirements in Section 1002.01, Florida Statutes (F.S.) <a href='http://www.leg.state.fl.us/statutes/index.cfm?mode=View%20Statutes&SubMenu=1&App_mode=Display_Statute&Search_String=1002.01&URL=1000-1099/1002/Sections/1002.01.html' target='_blank'>Student and Parental Rights and Educational Choices</a>.`}
                            options={yesNoList}
                        />
                    }

                    {
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <>
                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.isFlviRegistered.name}>
                                <RadioList
                                    {...studentDetailsForm.isFlviRegistered}
                                    disabled={readOnly}
                                    legend={`Is this student registered with the Florida Low Vision Initiative (FLVI)? Visit the <a href='https://www.livebinders.com/play/play?id=2678832#anchor' target='_blank'>FLVI Website</a> to learn more about eligibility and services.`}
                                    options={yesNoList}
                                />
                            </StudentFieldChangeLogModal>

                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.isDualSensoryImpaired.name}>
                                <RadioList
                                    {...studentDetailsForm.isDualSensoryImpaired}
                                    disabled={readOnly}
                                    legend={`Does this student have a dual sensory impairment?`}
                                    options={yesNoList}
                                />
                            </StudentFieldChangeLogModal>
                        </>
                    }

                    {
                        formVisibility.isDualSensoryImpaired &&
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <StudentFieldChangeLogModal
                            {...studentChangeLogModalProps}
                            fieldName={studentDetailsForm.isFaviRegistered.name}>
                            <RadioList
                                {...studentDetailsForm.isFaviRegistered}
                                disabled={readOnly}
                                legend={`Is this student registered with the Florida & Virgin Islands Deafblind Collaborative (FAVI)? Visit the <a href='https://deafblind.ufl.edu/' target='_blank'>FAVI Website</a> to learn more about their Census and resources.`}
                                options={yesNoList}
                            />
                        </StudentFieldChangeLogModal>
                    }

                    {
                        formVisibility.isAdGradeLevel &&
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <StudentFieldChangeLogModal
                            {...studentChangeLogModalProps}
                            fieldName={studentDetailsForm.hasInstructionalHours.name}>
                            <RadioList
                                {...studentDetailsForm.hasInstructionalHours}
                                disabled={readOnly}
                                legend={`Was this student registered for at least 120 hours of documented educational instruction during the preceding calendar year?`}
                                options={yesNoList}
                            />
                        </StudentFieldChangeLogModal>
                    }

                    {
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <>
                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.primaryReadingMedium.name}>
                                <SelectField
                                    {...studentDetailsForm.primaryReadingMedium}
                                    disabled={readOnly}
                                    helpText={`<p><b>IP</b>  Auditory Readers: Students primarily using a reader or auditory materials in their studies.<br/> <b>Braille Readers:</b> Students primarily using braille in their studies.<br/><b>Pre-readers:</b> All infants and preschoolers; students working on or toward a readiness level; older students with reading potential. <br/> <b>Symbolic readers:</b> A symbolic reader is one that accesses printed materials with tangible two- or three-dimensional symbols. Examples of these symbols are photographs, line pictures, real objects, miniature objects, partial objects, etc. These symbols may or may not have braille or print attached, but the student needs the additional symbol to access the learning material. <br /> <b>Visual Readers:</b> Students primarily using print in their studies. <br /> <b>Note: All students in infant and preschool programs MUST be reported as pre-readers.</b></p>`}
                                    label={`Primary Reading Medium`}
                                    options={getReadingMediumOptions(
                                        "primaryReadingMedium",
                                        PRIMARY_READING_MEDIUM_LIST
                                    )}
                                />
                            </StudentFieldChangeLogModal>

                            {
                                !formVisibility.hideReadingMediums &&
                                <>
                                    <StudentFieldChangeLogModal
                                        {...studentChangeLogModalProps}
                                        fieldName={studentDetailsForm.secondaryReadingMedium.name}>
                                        <SelectField
                                            {...studentDetailsForm.secondaryReadingMedium}
                                            disabled={readOnly}
                                            label={`Secondary Reading Medium`}
                                            options={getReadingMediumOptions(
                                                "secondaryReadingMedium",
                                                SECONDARY_READING_MEDIUM_LIST
                                            )}
                                        />
                                    </StudentFieldChangeLogModal>

                                    <StudentFieldChangeLogModal
                                        {...studentChangeLogModalProps}
                                        fieldName={studentDetailsForm.otherReadingMedium.name}>
                                        <SelectField
                                            {...studentDetailsForm.otherReadingMedium}
                                            disabled={readOnly}
                                            label={`Other Reading Medium`}
                                            options={getReadingMediumOptions(
                                                "otherReadingMedium",
                                                OTHER_READING_MEDIUM_LIST
                                            )}
                                        />
                                    </StudentFieldChangeLogModal>
                                </>
                            }
                        </>
                    }

                    {
                        isEditing && formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <>
                            {
                                formVisibility.canEditApprovalStatus &&
                                <StudentFieldChangeLogModal
                                    {...studentChangeLogModalProps}
                                    fieldName={studentDetailsForm.studentApprovalStatus.name}>
                                    <SelectField
                                        {...studentDetailsForm.studentApprovalStatus}
                                        disabled={readOnly}
                                        includeDefaultOption={false}
                                        options={STUDENT_APPROVAL_STATUS_LIST}
                                        onChange={handleApprovalStatusChange}
                                    />
                                </StudentFieldChangeLogModal>
                            }

                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.studentStatus.name}>
                                <SelectField
                                    {...studentDetailsForm.studentStatus}
                                    disabled={readOnly}
                                    includeDefaultOption={false}
                                    options={STUDENT_STATUS_LIST}
                                />
                            </StudentFieldChangeLogModal>

                            {
                                formVisibility.isInactive &&
                                <StudentFieldChangeLogModal
                                    {...studentChangeLogModalProps}
                                    fieldName={studentDetailsForm.studentInactiveReason.name}>
                                    <SelectField
                                        {...studentDetailsForm.studentInactiveReason}
                                        disabled={readOnly}
                                        includeDefaultOption
                                        options={STUDENT_INACTIVE_REASON_LIST}
                                    />
                                </StudentFieldChangeLogModal>
                            }
                        </>
                    }

                    {
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <>
                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.language.name}>
                                <SelectField
                                    {...studentDetailsForm.language}
                                    disabled={readOnly}
                                    label={`Language`}
                                    options={LANGUAGE_LIST}
                                />
                            </StudentFieldChangeLogModal>

                            <StudentFieldChangeLogModal
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.noteToFimc.name}>
                                <TextField
                                    {...studentDetailsForm.noteToFimc}
                                    disabled={readOnly}
                                    helpText={`<p>Please use this field to identify or cross reference if the student is a twin, triplet, etc. Also include any secondary visual factors in this field.</p>`}
                                    label={`Note to FIMC-VI (optional)`}
                                />
                            </StudentFieldChangeLogModal>

                            <RadioList
                                {...studentDetailsForm.certification}
                                disabled={readOnly}
                                legend={`I, ${patronName}, certify that the above information is accurate and fully documented.`}
                                options={yesNoList}
                            />

                            {
                                formVisibility.isNotCertified &&
                                <CheckBoxField
                                    {...studentDetailsForm.understandsNotCertified}
                                    checked={studentDetailsForm.understandsNotCertified.value}
                                    disabled={readOnly}
                                    label={
                                        "I understand that I have not certified the accuracy of this information."
                                    }
                                    value={
                                        studentDetailsForm.understandsNotCertified.value ? "" : "true"
                                    }
                                />
                            }
                        </>
                    }
                </>
            }
        </>
    );
};

StudentRegistrationForm.propTypes = {
    checkAndSetError: PropTypes.func.isRequired,
    checkForDuplicateStudents: PropTypes.func.isRequired,
    districts: PropTypes.arrayOf(PropTypes.object),
    duplicateStudents: PropTypes.arrayOf(PropTypes.object),
    formVisibility: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    patronName: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    setWarnings: PropTypes.func.isRequired,
    studentDetailsForm: PropTypes.object.isRequired
};
