export const apiConstants = {
    apiRedirectPath: "/sign-in",
    contentType: {
        JSON: `application/json`,
        MULTI: `multipart/form-data`
    },
    successMessage: {
        GENERIC: `Success`,
        user: {
            REGISTER: `Your registration has been successfully submitted and is now pending approval. Once you are approved, you will be notified via email.`,
            LOGOUT: `You have successfully signed out.`,
            REGISTER_WITH_INVALID_EMAIL: `You have successfully registered but there was an issue sending your confirmation email.  Please update your email address in your profile or contact tech support for help.`,
            FORGOT_PASSWORD: `Email sent. Check your inbox for further instructions.`,
            UPDATE_PASSWORD: `Your password has been successfully updated.`,
            UPDATE: `Successfully updated the user.`
        },
        approval: {
            APPROVAL_UPDATED: `Successfully updated the approval status.`
        },
        catalog: {
            CATALOG_ITEM_ADDED: `Successfully added a new item to the catalog.`,
            CATALOG_ITEM_UPDATED: `Successfully updated the item in the catalog.`,
            CATALOG_VENDOR_ADDED: `Successfully added a new vendor to the catalog.`,
            CATALOG_VENDOR_UPDATED: `Successfully updated the vendor in the catalog.`,
            CATALOG_PUBLISHER_ADDED: `Successfully added a new publisher to the catalog.`,
            CATALOG_PUBLISHER_UPDATED: `Successfully updated the publisher in the catalog.`
        },
        student : {
            REGISTER: `Your student's registration information has been successfully submitted and is now pending approval. Once they are approved, you will be notified via email.`,
            UPDATE: `Successfully updated the student.`,
        }
    },
    errorMessage: {
        GENERIC: `Error`,
        user: {
            REGISTER: `There was an issue registering.  Please try again.`,
            REGISTER_WITH_INVALID_EMAIL: 'There was an error while attempting to send email.',
            REGISTER_WITH_PASSWORD_MISMATCH: 'Password must match confirmation field.',
            FORGOT_PASSWORD: `There was an issue sending the email.  Please try again.`,
            FORGOT_PASSWORD_INVALID_EMAIL: 'There was an error while attempting to send email.',
            UPDATE_PASSWORD: `There was an issue updating your password.  Please try again.`,
        }
    }
};