import React, {useEffect, useState} from "react";
import {PageTitle} from "../../../Components/Landmarks";
import PropTypes from "prop-types";
import UserProfileApi from "./userProfileApi";
import {UserProfileView} from "./UserProfileView";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {userDetailsModel} from "../../../Components/User/userDetailsFactory";
import {locations} from "../../../Utilities/Location";
import {allow, policyEvents} from "../../../Components/Authorize";
import {updateFullName} from "../../../Utilities/LocalStorage/storageUtilities";

export const UserProfileContainer = ({
                                         districts,
                                         handleApiCall,
                                         isLoading,
                                         patronId,
                                         redirect,
                                         setWarnings,
                                         userId
                                     }) => {

    const [userDetails, setUserDetails] = useState(null);

    const clearUserDetailsState = () => {
        setUserDetails(null);
    };

    const handleEditUser = (userDetails) => {

        const userDetailsToUpdate = userDetailsModel(
            {
                ...userDetails,
                patronRole: userDetails.jobTitle
            });

        handleApiCall(() => UserProfileApi.editUserDetails(userDetailsToUpdate, userId), () => {
            if (userId === patronId) {
                updateFullName(userDetailsToUpdate.firstName, userDetailsToUpdate.lastName);
            }
            allow(policyEvents.VIEW) ? redirect(locations.USER_MANAGEMENT.path) : redirect(locations.DASHBOARD.path);
        });
    };

    const loadUserDetailsResult = (result) => {
        const userDetails = userDetailsModel({...result});
        setUserDetails(userDetails);
    };

    useEffect(() => {

        const loadUserDetails = () => {
            handleApiCall(() => UserProfileApi.getUserDetails(patronId, userId), loadUserDetailsResult)
        };

        clearUserDetailsState();
        loadUserDetails();
    }, [patronId]);


    if (!districts)
        return null;

    return <>
        <PageTitle h1={`User Profile Update`}/>
        {
            !isNullOrUndefined(userDetails) &&
            <UserProfileView
                districts={districts}
                handleEditUser={handleEditUser}
                isLoading={isLoading}
                redirect={redirect}
                setWarnings={setWarnings}
                patronDetails={userDetails}
            />
        }
    </>;
};

UserProfileContainer.propTypes = {
    districts: PropTypes.array,
    handleApiCall: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    patronId: PropTypes.string,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
};