import React, {useState} from "react";
import PropTypes from "prop-types";
import {
    Button,
    checkAndSetError,
    clearInputFormErrorFields,
    convertInputFormToObject,
    DateTimePickerField,
    InputForm,
    inputFormIsValid,
    NumberField,
    SelectField,
    TextArea,
    TextField,
    useInputForm,
} from "../../Components/Inputs";
import {GridCell, gridConstants} from "../../Components/Grid";
import {AVAILABILITY_LIST, FORMAT_LIST, GRADE_LEVEL_LIST} from "./libraryConstants";
import {resourceModel} from "./libraryFactory";
import {isStartBeforeEnd} from "../../Utilities/Types/dateUtilities";
import {sortArrayOfObjectsByProperty} from "../../Utilities/Types/arrayUtilities";
import {LibraryItemCopy} from "./LibraryItemCopy";

export const LibraryItemView = ({
                                    handleSaveResource,
                                    handleReturn,
                                    item,
                                    publishers,
                                    vendors,
                                }) => {
    const itemForm = useInputForm(resourceModel(item));
    const [editCopyId, setEditCopyId] = useState("");
    const isEditing = !!editCopyId;

    const handleDone = (data) => {

        let updatedCopies= [...itemForm.copies.value]
            .filter(c => c.copyId !== data.copyId);
        updatedCopies.push(data);

        updatedCopies = sortArrayOfObjectsByProperty(updatedCopies, "number");
        itemForm.copies.setValue(updatedCopies);

        setEditCopyId("");
    }

    const handleSubmitClick = () => {
        clearInputFormErrorFields(itemForm);

        const isStartDateAfterEndDate = !isStartBeforeEnd(itemForm.whatsNewStartDate.value, itemForm.whatsNewEndDate.value)
        const verifyStartAndEndDate = itemForm.whatsNewStartDate.value && itemForm.whatsNewEndDate.value;

        if (verifyStartAndEndDate && isStartDateAfterEndDate) {
            itemForm.whatsNewStartDate.setError("Start date must be before end date.");
            itemForm.whatsNewEndDate.setError("End date must be after start date.");
        }

        checkAndSetError([
            itemForm.availability,
            itemForm.cost,
            itemForm.format,
            itemForm.title,
            itemForm.volume,
        ]);

        if (inputFormIsValid(itemForm)) {
            const dirtyResource = convertInputFormToObject(itemForm);
            handleSaveResource(dirtyResource);
        }
    }

    return <>
        <InputForm name={`itemForm`}>
            <TextField {...itemForm.title} medium_columns={gridConstants.column.SIX}/>
            <TextField {...itemForm.author} medium_columns={gridConstants.column.SIX}/>
            <SelectField {...itemForm.availability} medium_columns={gridConstants.column.SIX}
                         options={AVAILABILITY_LIST}/>
            <SelectField {...itemForm.format} medium_columns={gridConstants.column.SIX} options={FORMAT_LIST}/>
            <TextArea {...itemForm.description} medium_columns={gridConstants.column.SIX}/>
            <NumberField isPositiveNumberOnly isWholeNumberOnly {...itemForm.numberOfVolumes}
                         medium_columns={gridConstants.column.SIX} minValue={1}/>
            <SelectField {...itemForm.gradeLevel} medium_columns={gridConstants.column.SIX} options={GRADE_LEVEL_LIST}/>

            <TextField {...itemForm.previewIsbn} medium_columns={gridConstants.column.SIX}/>
            <TextField {...itemForm.isbn10} medium_columns={gridConstants.column.SIX}/>
            <TextField {...itemForm.isbn13} medium_columns={gridConstants.column.SIX}/>

            <hr/>
            <SelectField {...itemForm.publisherId} medium_columns={gridConstants.column.SIX} label={`Publisher`}
                         options={publishers}/>
            <SelectField {...itemForm.vendorId} medium_columns={gridConstants.column.SIX} label={`Vendor`}
                         options={vendors}/>
            <NumberField {...itemForm.cost} isPositiveNumberOnly medium_columns={gridConstants.column.SIX}/>
            <DateTimePickerField {...itemForm.copyright} medium_columns={gridConstants.column.SIX}/>

            <hr/>
            <DateTimePickerField {...itemForm.whatsNewStartDate} medium_columns={gridConstants.column.SIX}/>
            <DateTimePickerField {...itemForm.whatsNewEndDate} medium_columns={gridConstants.column.SIX}/>

            <hr/>
            <NumberField {...itemForm.numberOfCopiesToAdd} isPositiveNumberOnly isWholeNumberOnly
                         medium_columns={gridConstants.column.SIX}/>
            <GridCell>
                <LibraryItemCopy
                    editCopyId={editCopyId}
                    handleDone={handleDone}
                    isEditing={isEditing}
                    itemForm={itemForm}
                    setEditCopyId={setEditCopyId}
                />
            </GridCell>

            <hr/>
            <GridCell className={`text-center`}>
                <Button disabled={isEditing} isPrimary isCollapsed label={'Save'} name={`btnSubmit`} onClick={handleSubmitClick}/>
                <Button disabled={isEditing} isCollapsed label={'Cancel'} name={`btnCancel`} onClick={handleReturn}/>
            </GridCell>
        </InputForm>
    </>;
};

LibraryItemView.propTypes = {
    handleSaveResource: PropTypes.func.isRequired,
    handleReturn: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    publishers: PropTypes.array.isRequired,
    vendors: PropTypes.array.isRequired,
};