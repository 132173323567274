import React from "react";
import PropTypes from "prop-types";
import {ExternalLink} from "../../../Components/Link";
import {CheckBoxField} from "../../../Components/Inputs";

export const StudentRegistrationEligibilityRequirements = ({readOnly,studentDetailsForm}) => {
    return <>
        <ul>
            <li>
                Signed <ExternalLink label={`Parental/Guardian Consent`}
                                     url={`https://hillsborough.sharepoint.com/:f:/s/FIMC-VI_ProfDev-WebsiteLive/EuRYl-e8PxBNjpPuXFOGvZ8BJ2XeMYqmEIZ_mMEZLB5VMg?e=urM4FB`}/> to
                share information with FIMC-VI
                and APH is on file with the district.
            </li>
            <ul>
                <li>
                    For students under the age of 18 years, the consent must be signed
                    by parents/guardians.
                </li>
                <li>
                    For students that have reached the age of majority, consent must
                    be signed by the student.
                </li>
            </ul>
            <li>
                Student is enrolled in a formally organized public or private,
                nonprofit education program of less than college level.
            </li>
            <ul>
                <li>
                    Educational programs include public, private, and parochial
                    schools as well as vocational and rehabilitation programs for
                    adults and detention centers.
                </li>
                <li>
                    Infants, toddlers, preschool, homebound, and homeschooled students
                    are also included.
                </li>
            </ul>
            <li>
                Student has a written education plan (IEP, IFSP, SP, IRP, 504 Plan,
                etc.).
            </li>
            <ul>
                <li>
                    For homeschool students, the requirements in Section 1002.01,
                    Florida Statutes (F.S.) <ExternalLink
                    label={`Student and Parental Rights and Educational Choices`}
                    url={`http://www.leg.state.fl.us/statutes/index.cfm?mode=View%20Statutes&SubMenu=1&App_mode=Display_Statute&Search_String=1002.01&URL=1000-1099/1002/Sections/1002.01.html`}/> have
                    been met.
                </li>
            </ul>
            <li>Student</li>
            <ul>
                <li>
                    is a student with a visual impairment as specified in
                    <ExternalLink label={`6A-6.03014, FAC`} url={`https://olo.sdhc.k12.fl.us/images/6A-6.03014.pdf`}/>;
                    or
                </li>
                <li>
                    is a student with a dual-sensory impairment as specified in
                    <ExternalLink label={`6A-6.03022, FAC`} url={`https://olo.sdhc.k12.fl.us/images/6A-6.03022.pdf`}/>;
                </li>
            </ul>
        </ul>

        <CheckBoxField
            {...studentDetailsForm.isEligible}
            checked={!!studentDetailsForm.isEligible.value}
            disabled={readOnly}
            label={`I confirm that the student meets the eligibility requirements.`}
            value={studentDetailsForm.isEligible.value ? "" : "true"}
        />

    </>;
};

StudentRegistrationEligibilityRequirements.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    studentDetailsForm: PropTypes.object.isRequired
};
