import {
    EDUCATIONAL_FACILITY,
    GRADE_LEVEL,
    STUDENT_STATUS,
    COMPLETE_READING_MEDIUMS,
    getGradeAgeRanges,
    NO_API_RESULTS
} from "../studentConstants";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {allow, policyEvents} from "../../../Components/Authorize";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";

function validateGrade(dob, gradeLevel) {
    const dateOfBirth = new Date(dob);
    const gradeAgeRanges = getGradeAgeRanges();
    const gradeAgeRange = gradeAgeRanges[gradeLevel];

    return dateOfBirth >= gradeAgeRange[0] && dateOfBirth <= gradeAgeRange[1];
}

export const studentRegistrationFormVisibility = ({
                                                      duplicateStudents,
                                                      isEditing,
                                                      previousStudentDetails,
                                                      studentDetailsForm,
                                                      yesNoObject,
                                                  } = {}) => ({

    isNeuroImpairment: studentDetailsForm.hasNeurologicalImpairment.value?.toString() === yesNoObject.Yes,

    isMedicalReport: studentDetailsForm.hasVisualImpairment.value?.toString() === yesNoObject.Yes,

    isNeurologicalImpairmentOrIsMedicalReport: (studentDetailsForm.hasNeurologicalImpairment.value?.toString() === yesNoObject.Yes || studentDetailsForm.hasVisualImpairment.value?.toString() === yesNoObject.Yes),

    isNotNeuroImpairmentAndMedicalReport:
        studentDetailsForm.hasNeurologicalImpairment.value?.toString() === yesNoObject.No &&
        studentDetailsForm.hasVisualImpairment.value?.toString() === yesNoObject.No,

    isHomeSchooledStudent: studentDetailsForm.educationalFacility.value === EDUCATIONAL_FACILITY.Homeschool.id,

    isDualSensoryImpaired: studentDetailsForm.isDualSensoryImpaired.value?.toString() === yesNoObject.Yes,

    isAdGradeLevel: studentDetailsForm.gradeLevel.value === GRADE_LEVEL.AD.toString(),

    saveIsHidden: studentDetailsForm.hasNeurologicalImpairment.value?.toString() === yesNoObject.No
        && studentDetailsForm.hasVisualImpairment.value?.toString() === yesNoObject.No,

    hasBirthdate: !isNullOrUndefined(studentDetailsForm.dateOfBirth.value) && studentDetailsForm.dateOfBirth.value !== ``,

    isParentalConsent: !!studentDetailsForm.hasSignedParentalConsent.value,

    isNotCertified: studentDetailsForm.certification.value?.toString() === yesNoObject.No,

    canEditApprovalStatus: allow(policyEvents.APPROVE_STUDENTS),

    isInactive: studentDetailsForm.studentStatus.value?.toString() === STUDENT_STATUS.Inactive.id,

    hideReadingMediums: studentDetailsForm.primaryReadingMedium.value?.toString() === COMPLETE_READING_MEDIUMS.PreReader.id || studentDetailsForm.primaryReadingMedium.value?.toString() === COMPLETE_READING_MEDIUMS.SymbolicReader.id,

    hasDuplicate: !isArrayNullOrEmpty(duplicateStudents),

    isFormDataChanged: (previousStudentDetails === null && !isEditing) || (previousStudentDetails !== null && (previousStudentDetails.firstName !== studentDetailsForm.firstName.value || previousStudentDetails.middleName !== studentDetailsForm.middleName.value || previousStudentDetails.lastName !== studentDetailsForm.lastName.value)),

    isOverrideOrNoDuplicate: duplicateStudents !== NO_API_RESULTS && (studentDetailsForm.overrideCheck.value || duplicateStudents?.length === 0 || (!studentDetailsForm.overrideCheck.value && isArrayNullOrEmpty(duplicateStudents))),

    showAgeOverride: studentDetailsForm.gradeLevel.value?.toString() && studentDetailsForm.dateOfBirth.value?.toString() && !validateGrade(studentDetailsForm.dateOfBirth.value, studentDetailsForm.gradeLevel.value ),
});